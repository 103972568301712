import { useAxiosRequest } from "api/axiosRequest"
import { BASE_URL } from "api/url_helper"
import moment from "moment"
import { VesselStateLadenOrBallast } from "../portPredictorReducer"
import { FetchPortTurnAroundTimeParams } from "./useFetchPortTurnAroundTime"
import { FetchTerminalTurnAroundTimeParams } from "./useFetchTerminalTurnAroundTime"
import { MarkerPoint } from "../components/PortPredictorMain"
import { VesselDetails, VesselPortStay, VesselPosition } from "modules/Prevetting/hooks/usePrevetAPI"

export enum PortPredictorCargoGroupTypes {
  Commodity = "commodity",
  MinorGroup = "minor_group",
  MajorGroup = "major_group",
}
export enum VesselPositionState {
  OpenSea = "Open Sea",
  LoadPort = "Load Port",
  DischargePort = "Discharge Port",
  Yard = "Yard",
}

export enum VesselActivity {
  Load = "L",
  Discharge = "D",
  Other = "O",
  Yard = "Y",
  Bunkering = "F"
}
interface PortPredictorVesselStateData {
  last_pos_time: string //e.g. "2023-07-17T16:55:36"
  last_pos_lon: number
  last_pos_lat: number
  last_pos_wkt: string //e.g. "POINT(69.26667 21.47333)"
  last_nav_sta: string
  last_nav_sta_desc: string
  last_master_destination: string
  last_master_destination_desc: string
  last_destination: string
  last_load_cargo: string
  last_event_port: string
  last_event_activity: VesselActivity
  last_event_date_from: string
  vessel_state: VesselStateLadenOrBallast
  pos_state: VesselPositionState
  last_sog: number
}

export type PortPredictorVesselStateDataWithMajorCargo = PortPredictorVesselStateData & { last_load_cargo_major_group: string | undefined }

export interface PortPredictorPortsData {
  locode: string // "DKAAB"
  port: string // "AABENRAA"
  lon: number // 9.42758
  lat: number // 55.03818
  pos_wkt: string // "POINT(9.42758 55.03818)"
}
export interface PortPredictorBerthsData {
  berth_id: string //"algopol_70",
  terminal_id: string // "AUHPT0002TD",
  terminal_name: string //"DALRYMPLE BAY COAL TERMINAL (D",
  port_unloc: string// "AUHPT",
  port_name: string //"HAY POINT"
}

export interface PortPredictorCargoesData {
  commodity: string //"woodpulp"
  major_group: string //"wood"
  minor_group: string //"wood"
}
export interface PortPredictorTurnAroundTimeData {
  imo: number
  vessel_name: string
  datetime_from: string //"2023-07-10T09:00:00"
  datetime_to: string //"2023-07-12T04:00:00"
  days_in_port: number //1.79166666666667
  cargo: string
}

export interface PortPredictorVesselSpeed {
  pos_date: string
  sog: number
  laden: boolean
}

export interface DistanceCalculatorResponse {
  distance: number
  path: string
}

function getMajorGroupForCargo({
  lastLoadCargo,
  cargoesData,
}: {
  lastLoadCargo: string | undefined
  cargoesData: PortPredictorCargoesData[]
}) {
  if (!lastLoadCargo || lastLoadCargo === "") {
    return
  }

  const found = cargoesData.filter((cargo) => {
    return lastLoadCargo === cargo.commodity
  })
  if (!found || found.length < 1) {
    return
  }

  if (found[0].major_group === "minor") {
    return found[0].minor_group
  }

  return found[0].major_group
}

function getCargoEndpoint(group: PortPredictorCargoGroupTypes) {
  if (group === PortPredictorCargoGroupTypes.Commodity) {
    return "cargo"
  }
  if (group === PortPredictorCargoGroupTypes.MinorGroup) {
    return "cargo-minor"
  }
  // Default set in PortPredictorArrivalInputs when declaring chosenCargoGroup
  return "cargo-major"
}

function getCargoGroupName(group: PortPredictorCargoGroupTypes) {
  if (group === PortPredictorCargoGroupTypes.Commodity) {
    return "cargo"
  }
  if (group === PortPredictorCargoGroupTypes.MinorGroup) {
    return "minor_group"
  }
  // Default set in PortPredictorArrivalInputs when declaring chosenCargoGroup
  return "major_group"
}

export function usePortPredictorAPI() {
  const request = useAxiosRequest()

  function getCargoes() {
    return request<any, PortPredictorCargoesData[]>({
      url: `${BASE_URL}/port-predictor/commodities`,
    }).then((response) => response?.data)
  }

  async function getChosenVesselState(imo: number): Promise<PortPredictorVesselStateDataWithMajorCargo> {
    const cargoesData = await getCargoes()
    const response = await request<any, PortPredictorVesselStateData>({
      url: `${BASE_URL}/port-predictor/vessel/state`,
      params: { imo },
    })
    const vesselState = response?.data
    const lastLoadCargo = vesselState?.last_load_cargo
    if (!cargoesData) {
      return {
        ...vesselState as PortPredictorVesselStateData,
        last_load_cargo_major_group: undefined
      }
    }
    const cargoMajorGroup = getMajorGroupForCargo({ lastLoadCargo, cargoesData })
    return {
      ...vesselState as PortPredictorVesselStateData,
      last_load_cargo_major_group: cargoMajorGroup
    }
  }

  function getDistance({ target, source }: { target: string, source: string }) {

    return request<any, DistanceCalculatorResponse>({
      url: `${BASE_URL}/distance/shortest`,
      params: { source, target },
    }).then((response) => response?.data)
  }

  async function getFuturePath(points: MarkerPoint[]) {
    const distances = []
    for (let i = 0; i < points.length - 1; i++) {
      const source = points[i];
      const sourceWkt = `POINT(${source.lon} ${source.lat})`
      const target = points[i + 1];
      const targetWkt = `POINT(${target.lon} ${target.lat})`
      const distance = await getDistance({ source: sourceWkt, target: targetWkt });
      distances.push(distance);
    }
    return distances;
  }

  function getPorts() {
    return request<any, PortPredictorPortsData[]>({
      url: `${BASE_URL}/port-predictor/ports`,
    }).then((response) => response?.data)
  }

  function getBerths({ portName }: { portName: string }) {
    return request<any, PortPredictorBerthsData[]>({
      url: `${BASE_URL}/port-predictor/berths?portName=${portName}`,
    }).then((response) => {
      const data = response?.data
      const terminalIds = data?.map((berth) => berth.terminal_id)
      const terminalIdsSet = new Set(terminalIds)
      const uniqTerminalIds = Array.from(terminalIdsSet)
      const result = [] as PortPredictorBerthsData[]
      uniqTerminalIds.forEach((termId) => {
        const found = data?.find((berth) => berth.terminal_id === termId)
        if (found) {
          result.push(found)
        }
      })
      return result
    })
  }


  function getCargoesFromPort({
    segment, locode, activity
  }: { segment: string, locode: string | undefined, activity: string | undefined }) {
    if (!locode || !activity || activity === VesselActivity.Other) {
      return []
    }
    
    return request<any, PortPredictorCargoesData[]>({
      url: `${BASE_URL}/port-predictor/port/commodities`,
      params: { segment, locode, activity }
    }).then((response) => response?.data)
  }

  function getChosenVesselSpeed(
    imo: number
  ): Promise<PortPredictorVesselSpeed[] | undefined> {
    const monthsAgo = moment(new Date())
      .subtract(6, "months")
      .format("YYYY-MM-DD")

    return request<any, PortPredictorVesselSpeed[]>({
      url: `${BASE_URL}/vetting/legs`,
      params: {
        imo,
        date_from: monthsAgo,
        date_to: moment(new Date()).format("YYYY-MM-DD"),
      },
    }).then((response) => {
      const data = response && response.data
      return data
    })
  }

  function getPortTurnAroundTime({
    segment,
    cargo,
    locode,
    activity,
    cargoGroup
  }: FetchPortTurnAroundTimeParams): Promise<PortPredictorTurnAroundTimeData[] | null> | undefined {
    if (!cargoGroup) {
      console.error("getPortTurnAroundTime: No cargoGroup to API call")
      return
    }

    const endpoint = getCargoEndpoint(cargoGroup)
    const groupName = getCargoGroupName(cargoGroup)

    return request<any, PortPredictorTurnAroundTimeData[]>({
      url: `${BASE_URL}/port-predictor/port/${endpoint}`,
      params: {
        segment,
        [groupName]: cargo,
        locode,
        activity,
      },
    }).then((response) => {
      if (!response || response.data.length < 1) {
        return null
      }

      return response.data
    })
  }

  function getTerminalTurnAroundTime({
    segment,
    cargo,
    locode,
    terminalId,
    activity,
    cargoGroup
  }: FetchTerminalTurnAroundTimeParams): Promise<PortPredictorTurnAroundTimeData[] | null> | undefined {
    if (!cargoGroup) {
      console.error("getPortTurnAroundTime: No cargoGroup to API call")
      return
    }

    const endpoint = getCargoEndpoint(cargoGroup)
    const groupName = getCargoGroupName(cargoGroup)

    return request<any, PortPredictorTurnAroundTimeData[]>({
      url: `${BASE_URL}/port-predictor/terminal/${endpoint}`,
      params: {
        segment,
        [groupName]: cargo,
        locode,
        terminal_id: terminalId,
        activity,
      },
    }).then((response) => {
      if (!response || response.data.length < 1) {
        return null
      }

      return response.data
    }).catch((error: any) => {
      if (error.response) {
        throw error
      } else {
        throw new Error("Network or other error")
      }
    })
  }

  async function getVesselPositions({ imo, months }: { imo: number | string, months: number }) {
    // The underlying interface, VesselDetails is from Rainbow API's prevet routes
    // Hence VesselPosition interface is imported from Prevet
    return request<any, VesselPosition[]>({
      url: `${BASE_URL}/port-predictor/vessel-positions`,
      params: { imo, months }
    }).then((response) => response?.data)
  }

  async function getVesselDetails(imo: number) {
    // The underlying interface, VesselDetails is from Rainbow API's prevet routes
    // Hence VesselDetails interface is imported from Prevet
    return request<any, VesselDetails>({
      url: `${BASE_URL}/port-predictor/vessel-details`,
      params: { imo }
    }).then((response) => response?.data)
  }


  async function getVesselStays(imo: number) {
    // This returns the last 5 years data
    // The underlying interface of VesselPortStay is from Rainbow API's prevet routs
    // Hence VesselPortStay is imported from Prevet
    return request<any, VesselPortStay[]>({
      url: `${BASE_URL}/port-predictor/vessel-stays`,
      params: { imo }
    }).then((response) => response?.data)
  }
  return {
    getChosenVesselState,
    getChosenVesselSpeed,
    getPortTurnAroundTime,
    getTerminalTurnAroundTime,
    getPorts,
    getBerths,
    getCargoesFromPort,
    getCargoes,
    getDistance,
    getFuturePath,
    getVesselPositions,
    getVesselDetails,
    getVesselStays
  }
}
