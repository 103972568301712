import { useEffect, useState } from "react"
import {
  MarkerType,
  PortPredictorJourneyMarker,
  PortPredictorJourneyState,
  PortPredictorMarkerArrival,
  PortPredictorMarkerDeparture,
  PortType,
  VesselStateLadenOrBallast,
} from "../portPredictorReducer"
import {
  PortPredictorCargoGroupTypes,
  PortPredictorPortsData,
  PortPredictorVesselSpeed,
  PortPredictorVesselStateDataWithMajorCargo,
  VesselPositionState,
} from "./usePortPredictorAPI"
import { useFetchPorts } from "./useFetchPorts"
import {
  SpeedDataAvgKey,
  VesselSpeedDataWithAverages,
  getVesselSpeedDataWithAverages,
} from "../components/PortPredictorDeparture/helpers"
import {
  createArrivalMarker,
  createDepartureMarker,
} from "../components/PortPredictorTimeline/createMarkerBase"

export const defaultTimeRangeForHistoricalVesselSpeed = 3

interface UseJourneyStartProps {
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo | undefined
  chosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined
  isChosenVesselSpeedSuccess: boolean
  isChosenVesselStateSuccess: boolean
  chosenVesselImo: number | undefined
  journeyMarkerState: PortPredictorJourneyState
  addJourneyStartMarkersArr: (payload: PortPredictorJourneyMarker[]) => void
  isStartOfJourney: boolean
}

export function useJourneyStart({
  chosenVesselStateData,
  chosenVesselSpeedData,
  isChosenVesselStateSuccess,
  isChosenVesselSpeedSuccess,
  addJourneyStartMarkersArr,
  isStartOfJourney,
}: UseJourneyStartProps) {
  const [journeyStartMarkersArr, setJourneyStartMarkersArr] = useState<
    PortPredictorJourneyMarker[] | undefined
  >()

  const { portsData, isPortsSuccess, isPortsLoading } = useFetchPorts()

  const speedDataWithAvg = getVesselSpeedDataWithAverages(chosenVesselSpeedData)

  useEffect(() => {
    if (!isStartOfJourney) {
      return
    }

    if (
      !isChosenVesselStateSuccess
      // !isPortsSuccess ||
      // !portsData
    ) {
      return
    }
    const journeyStartMarkersArr = createJourneyStart({
      speedDataWithAvg,
      chosenVesselStateData,
      portsData
    }) as PortPredictorJourneyMarker[]

    addJourneyStartMarkersArr(journeyStartMarkersArr)
    setJourneyStartMarkersArr(journeyStartMarkersArr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isChosenVesselSpeedSuccess,
    isChosenVesselStateSuccess,
    isPortsSuccess,
    isPortsLoading,
    chosenVesselStateData?.last_pos_time,
    chosenVesselStateData?.last_event_date_from,
    chosenVesselStateData?.vessel_state,
    chosenVesselStateData?.last_load_cargo_major_group,
    chosenVesselStateData?.pos_state,
    chosenVesselStateData?.last_pos_wkt,
    chosenVesselStateData?.last_event_activity,
    chosenVesselStateData?.last_event_port,
    isStartOfJourney,
  ])

  return {
    journeyStartMarkersArr,
    chosenVesselSpeedData,
    chosenVesselStateData,
  }
}

export function createJourneyStart({
  chosenVesselStateData,
  speedDataWithAvg,
  portsData,
}: {
  prevMarker?: PortPredictorMarkerDeparture
  chosenVesselStateData?: PortPredictorVesselStateDataWithMajorCargo
  speedDataWithAvg?: VesselSpeedDataWithAverages | undefined
  portsData?: PortPredictorPortsData[]
  masterDestinationDesc?: string
}) {
  if (!chosenVesselStateData) {
    return
  }

  const nextPort = findPortFromPortsData({
    portToFind: chosenVesselStateData.last_master_destination_desc,
    portsData,
  })
  if (chosenVesselStateData?.pos_state === "Open Sea") {
    if (
      chosenVesselStateData.vessel_state === VesselStateLadenOrBallast.Laden
    ) {
      return startFromLaden({
        nextPort,
        speedDataWithAvg,
        chosenVesselStateData,
      })
    }
    if (
      chosenVesselStateData.vessel_state === VesselStateLadenOrBallast.Ballast
    ) {
      const result = startFromBallast({
        nextPort,
        speedDataWithAvg,
        chosenVesselStateData,
      })
      return result
    }
  }
  if (chosenVesselStateData.pos_state === VesselPositionState.DischargePort) {
    return startFromDischargePort({
      speedDataWithAvg,
      chosenVesselStateData,
      nextPort,
    })
  }
  if (
    chosenVesselStateData.pos_state === VesselPositionState.LoadPort
  ) {
    return startFromLoadWithLaycan({
      chosenVesselStateData,
      speedDataWithAvg,
      nextPort
    })
  }
  if (
    chosenVesselStateData.pos_state === VesselPositionState.Yard
  ) {
    if (chosenVesselStateData.vessel_state === VesselStateLadenOrBallast.Ballast) {
      return startFromLoadWithLaycan({
        chosenVesselStateData,
        speedDataWithAvg,
        nextPort
      })
    }
    if (chosenVesselStateData.vessel_state === VesselStateLadenOrBallast.Laden) {
      return startFromDischargePort({
        chosenVesselStateData,
        speedDataWithAvg,
        nextPort
      })
    }
  }
}

/**
 * Case 1: 1. Load-w-laycan -> 2. Laden
 * Case 2: 1. Ballast 2. Load-w-laycan -> 3. Laden
 * Case 3: 1. Disch Port -> 2. Ballast 3. Load-w-laycan -> 4. Laden
 * Case 4: 1. Laden -> 2. Disch Port -> 3. Ballast 4. Load-w-laycan -> 5. Laden
 */

// Base case
export function startFromLoadWithLaycan({
  speedDataWithAvg,
  chosenVesselStateData,
  prevMarker,
  nextPort,
}: {
  prevMarker?: PortPredictorMarkerDeparture
  chosenVesselStateData?: PortPredictorVesselStateDataWithMajorCargo
  speedDataWithAvg: VesselSpeedDataWithAverages | undefined
  nextPort?: PortPredictorPortsData
  masterDestinationDesc?: string
}) {
  if (chosenVesselStateData) {
    const fromAisLoadPortMarker: PortPredictorMarkerArrival = {
      id: 0,
      arrivalDate: chosenVesselStateData.last_event_date_from,
      markerType: MarkerType.Arrival,
      vesselState: VesselStateLadenOrBallast.Laden,
      vesselPosition: chosenVesselStateData?.pos_state as VesselPositionState,
      cargo: chosenVesselStateData.last_load_cargo,
      cargoGroup: PortPredictorCargoGroupTypes.Commodity,
      lastPosTime: chosenVesselStateData.last_pos_time,
      turnAroundTime: undefined,
      distanceFromDeparture: undefined,
      portType: PortType.LoadWithLaycan,
      port: nextPort,
      berth: undefined,
      lastNavStaDesc: chosenVesselStateData.last_nav_sta_desc
    }

    const departureMarker = createDepartureMarker({
      prevMarker: fromAisLoadPortMarker,
      speedDataWithAvg
    })

    return [fromAisLoadPortMarker, departureMarker]
  }

  if (prevMarker) {
    // If prevMarker was Ballast
    // e.g. 1. Ballast -> (2. Load-w-laycan -> 3. Laden)

    const arrivalMarker = createArrivalMarker(prevMarker)

    const departureMarker = createDepartureMarker({
      prevMarker: { ...arrivalMarker, port: nextPort },
      speedDataWithAvg
    })

    return [{ ...arrivalMarker, port: nextPort }, departureMarker]
  }
}

export function startFromBallast({
  chosenVesselStateData,
  speedDataWithAvg,
  prevMarker,
  nextPort,
}: {
  speedDataWithAvg: VesselSpeedDataWithAverages | undefined
  chosenVesselStateData?: PortPredictorVesselStateDataWithMajorCargo
  prevMarker?: PortPredictorMarkerArrival
  nextPort?: PortPredictorPortsData
}) {

  const speedMonthsKey = speedDataWithAvg?.ballast.defaultMonthsKey
  const vesselSpeedSelected = speedDataWithAvg?.ballast[speedMonthsKey as SpeedDataAvgKey]?.avg

  if (chosenVesselStateData) {
    // Case 2: 1. Ballast 2. Load-w-laycan -> 3. Laden
    const openSeaBallastMarker = {
      id: 0,
      date: chosenVesselStateData.last_pos_time,
      markerType: MarkerType.Departure,
      vesselState: chosenVesselStateData.vessel_state,
      vesselSpeedSelected: vesselSpeedSelected,
      vesselPosition: VesselPositionState.OpenSea,
      cargo: undefined,
      cargoGroup: undefined,
      posWkt: chosenVesselStateData?.last_pos_wkt,
      daysToArrival: undefined,
      lastNavStaDesc: chosenVesselStateData.last_nav_sta_desc
    } as PortPredictorMarkerDeparture

    const result = [
      openSeaBallastMarker,
      ...(startFromLoadWithLaycan({
        prevMarker: openSeaBallastMarker,
        speedDataWithAvg,
        nextPort,
      }) as PortPredictorJourneyMarker[]),
    ]
    return result
  }

  if (prevMarker) {
    // Sub-timeline: 2. Ballast 3. Load-w-laycan -> 4. Laden
    // eg: 1. Disch Port -> ( 2. Ballast 3. Load-w-laycan -> 4. Laden )
    const fromPortBallastMarker = createDepartureMarker({
      prevMarker,
      speedDataWithAvg
    }) as PortPredictorMarkerDeparture

    return [
      fromPortBallastMarker,
      ...(startFromLoadWithLaycan({
        prevMarker: fromPortBallastMarker,
        speedDataWithAvg
      }) as PortPredictorJourneyMarker[]),
    ]
  }
}

export function startFromDischargePort({
  chosenVesselStateData,
  prevMarker,
  nextPort,
  speedDataWithAvg,
}: {
  chosenVesselStateData?: PortPredictorVesselStateDataWithMajorCargo
  prevMarker?: PortPredictorMarkerDeparture
  nextPort?: PortPredictorPortsData
  speedDataWithAvg: VesselSpeedDataWithAverages | undefined
}) {
  if (chosenVesselStateData) {
    // Case 3: 1. Disch Port -> 2. Ballast 3. Load-w-laycan -> 4. Laden
    const fromAisDischargePortMarker: PortPredictorMarkerArrival = {
      id: 0,
      arrivalDate: chosenVesselStateData.last_event_date_from,
      markerType: MarkerType.Arrival,
      vesselState: VesselStateLadenOrBallast.Ballast,
      vesselPosition: chosenVesselStateData?.pos_state as VesselPositionState,
      cargo: chosenVesselStateData.last_load_cargo,
      cargoGroup: PortPredictorCargoGroupTypes.Commodity,
      turnAroundTime: undefined,
      distanceFromDeparture: undefined,
      portType: PortType.Discharge,
      port: nextPort,
      berth: undefined,
      lastPosTime: chosenVesselStateData.last_pos_time,
      lastNavStaDesc: chosenVesselStateData.last_nav_sta_desc
    }

    return [
      fromAisDischargePortMarker,
      ...(startFromBallast({

        speedDataWithAvg,
        prevMarker: fromAisDischargePortMarker,
      }) as PortPredictorJourneyMarker[]),
    ]
  }

  if (prevMarker) {
    // Sub-timeline: 2. Disch Port -> 3. Ballast 4. Load-w-laycan -> 5. Laden
    // eg: 1. Laden -> (2. Disch Port -> 3. Ballast 4. Load-w-laycan -> 5. Laden)


    const prevMarkerLaden = createArrivalMarker(prevMarker)

    return [
      { ...prevMarkerLaden, port: nextPort },
      ...(startFromBallast({

        prevMarker: prevMarkerLaden,
        speedDataWithAvg,
        nextPort
      }) as PortPredictorJourneyMarker[]),
    ]
  }
}

export function startFromLaden({
  speedDataWithAvg,
  chosenVesselStateData,
  nextPort,
  prevMarker,
}: {
  chosenVesselStateData?: PortPredictorVesselStateDataWithMajorCargo
  speedDataWithAvg: VesselSpeedDataWithAverages | undefined
  prevMarker?: PortPredictorMarkerArrival
  nextPort?: PortPredictorPortsData
}) {
  const speedMonthsKey = speedDataWithAvg?.laden.defaultMonthsKey
  const vesselSpeedSelected = speedDataWithAvg?.laden[speedMonthsKey as SpeedDataAvgKey]?.avg

  if (chosenVesselStateData) {
    //1. Laden -> 2. Disch Port -> 3. Ballast 4. Load-w-laycan -> 5. Laden
    const openSeaLadenMarker = {
      id: 0,
      date: chosenVesselStateData.last_pos_time,
      markerType: MarkerType.Departure,
      vesselState: chosenVesselStateData.vessel_state,
      vesselSpeedSelected: vesselSpeedSelected,
      vesselPosition: VesselPositionState.OpenSea,
      cargo: chosenVesselStateData.last_load_cargo,
      cargoGroup: PortPredictorCargoGroupTypes.Commodity,
      posWkt: chosenVesselStateData?.last_pos_wkt,
      daysToArrival: undefined,
      lastNavStaDesc: chosenVesselStateData.last_nav_sta_desc
    } as PortPredictorMarkerDeparture

    return [
      openSeaLadenMarker,
      ...(startFromDischargePort({
        nextPort,
        prevMarker: openSeaLadenMarker,
        speedDataWithAvg,
      }) as PortPredictorJourneyMarker[]),
    ]
  }
}

export function findPortFromPortsData({
  portToFind,
  portsData,
}: {
  portToFind?: string
  portsData?: PortPredictorPortsData[]
}) {
  if (!portToFind || !portsData) {
    return
  }

  let foundPort = portsData.find((item) => item.port === portToFind)

  if (!foundPort) {
    foundPort = portsData.filter((item) => {
      // eg item.port is 'AQABA (EL AKABA)'
      const isMatch = matchPortNameWithMasterDestPortToFind({ portName: item.port, portToFind })

      if (isMatch) {
        return item
      }
    })[0]
  }

  return foundPort
}

function normalizePortNameFunc(portName: string) {
  // Convert to lowercase
  let normalized = portName.toLowerCase();
  // Remove any punctuation, like commas or parentheses
  normalized = normalized.replace(/[^\w\s]/g, ' ');
  // Replace multiple spaces with a single space
  normalized = normalized.replace(/\s+/g, ' ').trim();
  return normalized;
}

export function matchPortNameWithMasterDestPortToFind({ portName, portToFind }: { portName: string, portToFind: string }) {
  const normalizedPortName = normalizePortNameFunc(portName)
  const normalizedPortToFind = normalizePortNameFunc(portToFind)

  return normalizedPortName.includes(normalizedPortToFind)
}
