import { Map as LeafletMap } from "leaflet"
import { FuturePortLoc } from "modules/PortPredictor/helpers/mapHelpers"
import {
  PortPredictorTurnAroundTimeData,
  PortPredictorVesselSpeed,
  PortPredictorVesselStateDataWithMajorCargo,
} from "modules/PortPredictor/hooks/usePortPredictorAPI"
import {
  MarkerType,
  PortPredictorJourneyMarker,
  PortPredictorJourneyState,
  PortPredictorMarkerArrival,
} from "modules/PortPredictor/portPredictorReducer"
import { Dispatch, SetStateAction, useState } from "react"
import { VesselSegment } from "user/userService"
import { TypeOfTurnAroundTimeData } from "../PortPredictorArrival/PortOrTerminalTurnAroundTimeCheckboxes"
import { PortPredictorArrivalDisplay } from "../PortPredictorArrival/PortPredictorDisplayArrival"
import { PortPredictorPortTurnAroundTimeModal } from "../PortPredictorArrival/PortPredictorPortTurnAroundTimeModal"
import { getPortTurnAroundTimeOptions } from "../PortPredictorArrival/getPortTurnAroundTimeOptions"
import { PortPredictorDisplayDeparture } from "../PortPredictorDeparture/PortPredictorDisplayDeparture"
import { PortPredictorMarkerInputs } from "./PortPredictorMarkerInputs"
import { MarkerLatLon } from "../PortPredictorMain"

interface PortPredictorMarkerProps {
  currMarker: PortPredictorJourneyMarker
  index: number
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo | undefined
  chosenVesselSpeedData: PortPredictorVesselSpeed[] | undefined
  journeyMarkerState: PortPredictorJourneyState
  isStartOfJourney: boolean
  onUpdateMarker: (updatedMarker: PortPredictorJourneyMarker) => void
  onDeleteMarker: (deletedMarker: PortPredictorJourneyMarker) => void
  segment: VesselSegment
  showSpeedGraphModal: () => void
  changeOpenSeaToPort: () => void
  revertPortToOpenSea: () => void
  map: LeafletMap | null
  markers: MarkerLatLon[]
}

export function PortPredictorMarker({
  currMarker,
  index,
  journeyMarkerState,
  onUpdateMarker,
  onDeleteMarker,
  isStartOfJourney,
  segment,
  chosenVesselSpeedData,
  chosenVesselStateData,
  showSpeedGraphModal,
  changeOpenSeaToPort,
  revertPortToOpenSea,
  map,
  markers,
}: PortPredictorMarkerProps) {
  const [turnAroundTimeData, setTurnAroundTimeData] = useState<
    PortPredictorTurnAroundTimeData[] | undefined
  >()

  const [isPortTurnAroundTimeModalOpen, setIsPortTurnAroundTimeModalOpen] =
    useState(false)

  return (
    <>
      {isPortTurnAroundTimeModalOpen &&
        turnAroundTimeData &&
        currMarker.markerType === MarkerType.Arrival &&
        currMarker.port &&
        currMarker.cargo && (
          <PortPredictorPortTurnAroundTimeModal
            closePortTurnAroundTimeModal={() =>
              setIsPortTurnAroundTimeModalOpen(false)
            }
            isPortTurnAroundTimeModalOpen={isPortTurnAroundTimeModalOpen}
            portTurnAroundTimeChartOptions={getPortTurnAroundTimeOptions(
              turnAroundTimeData
            )}
            chosenPortLabel={
              currMarker.typeOfTurnAroundTimeData ===
              TypeOfTurnAroundTimeData.UsePortData
                ? currMarker.port.port
                : currMarker.berth?.terminal_name
            }
            chosenCargo={currMarker.cargo}
          />
        )}
      <div className="PortPredictor_MarkerInputContainer">
        <div className="PortPredictor_MarkerID">{currMarker.id + 1}</div>
        {/* {JSON.stringify(currMarker)} */}
        <PortPredictorMarkerInputs
          chosenVesselSpeedData={chosenVesselSpeedData}
          currMarker={currMarker}
          segment={segment}
          prevMarker={journeyMarkerState[index - 1]}
          isStartOfJourney={isStartOfJourney}
          onUpdateMarker={onUpdateMarker}
          onDeleteMarker={onDeleteMarker}
          showSpeedGraphModal={showSpeedGraphModal}
          turnAroundTimeData={turnAroundTimeData}
          setTurnAroundTimeData={setTurnAroundTimeData}
          chosenVesselStateData={chosenVesselStateData}
          map={map}
          markers={markers}
        />
      </div>
      <div className="PortPredictor_MarkerSpacer"></div>
      <div className="PortPredictor_MarkerCenter">
        <div className="PortPredictor_MarkerCenter_IconWrapper">
          <i className="ti-location-pin" />
        </div>
        <div className="PortPredictor_MarkerCenter_Line" />
      </div>
      <div className="PortPredictor_MarkerDisplayContainer">
        <div className="PortPredictor_MarkerDisplayTitle mb-1">Summary</div>
        {currMarker.markerType === MarkerType.Arrival && (
          <PortPredictorArrivalDisplay
            chosenVesselStateData={chosenVesselStateData}
            currMarker={currMarker}
            revertPortToOpenSea={() => {
              revertPortToOpenSea()
            }}
            segment={segment}
            turnAroundTimeData={turnAroundTimeData}
            setIsPortTurnAroundTimeModalOpen={setIsPortTurnAroundTimeModalOpen}
          />
        )}
        {currMarker.markerType === MarkerType.Departure && (
          <PortPredictorDisplayDeparture
            chosenVesselSpeedData={chosenVesselSpeedData}
            currMarker={currMarker}
            changeOpenSeaToPort={() => {
              changeOpenSeaToPort()
            }}
            nextMarker={
              journeyMarkerState[index + 1] as
                | PortPredictorMarkerArrival
                | undefined
            }
          />
        )}
      </div>
    </>
  )
}
