import { PortPredictorBerthsData } from "modules/PortPredictor/hooks/usePortPredictorAPI"
import { Form, Spinner } from "react-bootstrap"
import Select, { SingleValue } from "react-select"
import { PortPredictorBerthSelectOption } from "./PortPredictorArrivalInputs"
import { formatCargoLabel } from "../portPredictorFormatCargoLabelHelper"
import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"

interface PortPredictorBerthsSelectorProps {
  isBerthsLoading: boolean
  isBerthsError: boolean
  berthsData: PortPredictorBerthsData[] | undefined
  chosenBerth: PortPredictorBerthsData | undefined
  setChosenBerth: (port: PortPredictorBerthsData | undefined) => void
  isDisabled: boolean
  chosenCargo: string | undefined
  isTerminalTurnAroundTimeLoading: boolean
  isTerminalTurnAroundTimeError: boolean
}

// Research API provides all berths and we display the terminal names of the berths.
// A terminal can have many berths.
export function PortPredictorBerthsSelector({
  isBerthsLoading,
  isBerthsError,
  berthsData,
  chosenBerth,
  setChosenBerth,
  isDisabled,
  chosenCargo,
  isTerminalTurnAroundTimeLoading,
  isTerminalTurnAroundTimeError,
}: PortPredictorBerthsSelectorProps) {
  const { getTabStateKeyValue } = useItineraryContext()
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center">
        <Form.Label className="PortPredictor_FormLabel fw-bold">
          <>Terminal</>
        </Form.Label>
        {isBerthsError && (
          <div>
            There was an error loading available terminals, please contact
            support.
          </div>
        )}

        {berthsData && (
          <Select
            isDisabled={isDisabled || !getTabStateKeyValue('isEditMode')}
            placeholder={
              !isDisabled && isBerthsLoading && berthsData === undefined ? (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    variant="light"
                  />
                  <span className="ms-1">Loading Terminals...</span>
                </div>
              ) : (
                "Select Terminal"
              )
            }
            className="PortPredictor_InputGroup"
            options={berthsData
              .sort((a, b) => a.terminal_name.localeCompare(b.terminal_name))
              ?.map((berth) => {
                return {
                  label: berth.terminal_name,
                  value: berth.terminal_id,
                }
              })}
            value={
              chosenBerth && {
                label: chosenBerth.terminal_name,
                value: chosenBerth.terminal_id,
              }
            }
            onChange={(
              selectedBerth: SingleValue<PortPredictorBerthSelectOption>
            ) => {
              if (!selectedBerth || !berthsData) {
                return
              }
              const chosen = berthsData.find(
                (berth) => berth.terminal_id === selectedBerth.value
              )
              setChosenBerth(chosen)
            }}
          />
        )}
      </div>
      {chosenBerth &&
        chosenBerth.terminal_id &&
        chosenCargo &&
        isTerminalTurnAroundTimeLoading && (
          <div className="PortPredictor_InputWrapper mt-2">
            <Spinner as="span" animation="border" size="sm" variant="light" />
            <span className="ms-1">
              Loading Terminal Turn Around Time for Last 10 Vessels
            </span>
          </div>
        )}
      {isTerminalTurnAroundTimeError && chosenCargo && (
        <div className="PortPredictor_InputWrapper mt-2">
          <span className="ms-1">
            No info for {formatCargoLabel(chosenCargo)} at terminal{" "}
            {chosenBerth?.terminal_name}
          </span>
        </div>
      )}
    </div>
  )
}
