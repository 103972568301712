import BigNumber from "bignumber.js"
import moment from "moment"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import Select, { SingleValue } from "react-select"

import { Map as LeafletMap } from "leaflet"
import { useFetchBerths } from "modules/PortPredictor/hooks/useFetchBerths"
import { useFetchTerminalTurnAroundTime } from "modules/PortPredictor/hooks/useFetchTerminalTurnAroundTime"
import { VesselSegment } from "user/userService"
import { useFetchCargoesFromPort } from "../../hooks/useFetchCargoesFromPort"
import { useFetchDistance } from "../../hooks/useFetchDistance"
import {
  TurnAroundTimeMinMedianMax,
  useFetchPortTurnAroundTime,
} from "../../hooks/useFetchPortTurnAroundTime"
import { useFetchPorts } from "../../hooks/useFetchPorts"
import {
  PortPredictorBerthsData,
  PortPredictorCargoGroupTypes,
  PortPredictorPortsData,
  PortPredictorTurnAroundTimeData,
  PortPredictorVesselStateDataWithMajorCargo,
  VesselActivity,
  VesselPositionState,
} from "../../hooks/usePortPredictorAPI"
import {
  MarkerType,
  PortPredictorJourneyMarker,
  PortPredictorMarkerArrival,
  PortPredictorMarkerDeparture,
  PortType,
} from "../../portPredictorReducer"
import { MarkerLatLon } from "../PortPredictorMain"
import {
  PortOrTerminalTurnAroundTimeCheckBoxes,
  TypeOfTurnAroundTimeData,
} from "./PortOrTerminalTurnAroundTimeCheckboxes"
import { PortPredictorBerthsSelector } from "./PortPredictorArrivalBerthsSelector"
import { PortPredictorDatePicker } from "./PortPredictorArrivalDatePicker"
import { PortPredictorMinMedianMaxButtons } from "./PortPredictorArrivalMinMedianMaxButtons"
import { PortPredictorPortsSelector } from "./PortPredictorArrivalPortsSelector"
import { PortPredictorCargoInputs } from "./PortPredictorCargoInputs"
import { PortPredictorDaysInPortInput } from "./PortPredictorDaysInPortInput"
import { PortPredictorDistanceToPortInput } from "./PortPredictorDistanceToPortInput"
import {
  calcDaysFromDeparture,
  findVesselPortType,
  getVesselStateForPortType,
} from "./helpers"
import { useItineraryContext } from "modules/PortPredictor/hooks/itineraryContext"
export interface PortPredictorChosenCargo {
  cargo: string | undefined
  cargoGroup: PortPredictorCargoGroupTypes | undefined
}
export interface PortPredictorPortSelectOption {
  label: string // Corresponds to port name
  value: string // Corresponds to port locode
}
export interface PortPredictorBerthSelectOption {
  label: string // Corresponds to terminal_name
  value: string // Corresponds to terminal_id
}

interface PortPredictorArrivalInputsProps {
  currMarker: PortPredictorMarkerArrival
  segment: VesselSegment
  prevMarker: PortPredictorMarkerDeparture | undefined
  isStartOfJourney: boolean
  turnAroundTimeData: PortPredictorTurnAroundTimeData[] | undefined
  onUpdateMarker: (args: PortPredictorJourneyMarker) => void
  onDeleteMarker: (args: PortPredictorJourneyMarker) => void
  setTurnAroundTimeData: Dispatch<
    SetStateAction<PortPredictorTurnAroundTimeData[] | undefined>
  >
  chosenVesselStateData: PortPredictorVesselStateDataWithMajorCargo | undefined
  map: LeafletMap | null
  markers: MarkerLatLon[]
}

export interface PortPredictorPortTypeSelectOption {
  label:
    | "Discharge Port"
    | "Load Port"
    | "Discharge Port w Arrival Window"
    | "Load Port w Laycan"
    | "Bunkering / Other"
  value: PortType
  activity: VesselActivity
}

export const portTypes: PortPredictorPortTypeSelectOption[] = [
  {
    label: "Discharge Port",
    value: PortType.Discharge,
    activity: VesselActivity.Discharge,
  },
  { label: "Load Port", value: PortType.Load, activity: VesselActivity.Load },
  {
    label: "Discharge Port w Arrival Window",
    value: PortType.DischargeWithArrivalWindow,
    activity: VesselActivity.Discharge,
  },
  {
    label: "Load Port w Laycan",
    value: PortType.LoadWithLaycan,
    activity: VesselActivity.Load, // User to query Research API for turnAround time
  },
  {
    label: "Bunkering / Other",
    value: PortType.Other,
    activity: VesselActivity.Other,
  },
]

export function PortPredictorArrivalInputs({
  currMarker,
  segment,
  prevMarker,
  isStartOfJourney,
  onDeleteMarker,
  onUpdateMarker,
  setTurnAroundTimeData,
  turnAroundTimeData,
  chosenVesselStateData,
  map,

  markers,
}: PortPredictorArrivalInputsProps) {
  const { getTabStateKeyValue } = useItineraryContext()
  const [chosenPort, setChosenPort] = useState<
    PortPredictorPortsData | undefined
  >(currMarker.port)

  const [chosenBerth, setChosenBerth] = useState<
    PortPredictorBerthsData | undefined
  >(currMarker.berth)

  const [chosenPortType, setChosenPortType] = useState<
    PortPredictorPortTypeSelectOption | undefined
  >(findVesselPortType(currMarker.portType))

  const [turnAroundTime, setTurnAroundTime] = useState<number | undefined>(
    currMarker.turnAroundTime
  )

  const { portsData, isPortsLoading, isPortsError } = useFetchPorts()
  const { berthsData, isBerthsLoading, isBerthsError } = useFetchBerths({
    portName: currMarker.port?.port,
  })

  const { cargoesData, isCargoesLoading, isCargoesError } =
    useFetchCargoesFromPort({
      segment,
      locode: chosenPort?.locode,
      activity: chosenPortType?.activity,
    })

  const [chosenCargo, setChosenCargo] = useState<{
    cargo: string | undefined
    cargoGroup: PortPredictorCargoGroupTypes | undefined
  }>({
    cargo: currMarker.cargo,
    cargoGroup: currMarker.cargoGroup,
  })

  const [typeOfTurnAroundTimeData, setTypeOfTurnAroundTimeData] =
    useState<TypeOfTurnAroundTimeData>(TypeOfTurnAroundTimeData.UsePortData)

  const {
    portTurnAroundTimeData,
    portTurnAroundTimeMinMedianMax,
    isPortTurnAroundTimeLoading,
    isPortTurnAroundTimeSuccess,
  } = useFetchPortTurnAroundTime({
    segment,
    cargo: chosenCargo.cargo,
    cargoGroup: chosenCargo.cargoGroup,
    locode: chosenPort?.locode,
    activity: chosenPortType?.activity,
  })

  const {
    terminalTurnAroundTimeMinMedianMax,
    terminalTurnAroundTimeData,
    isTerminalTurnAroundTimeLoading,
    isTerminalTurnAroundTimeError,
    isTerminalTurnAroundTimeSuccess,
  } = useFetchTerminalTurnAroundTime({
    segment,
    cargo: chosenCargo.cargo,
    cargoGroup: chosenCargo.cargoGroup,
    locode: chosenPort?.locode,
    terminalId: chosenBerth?.terminal_id,
    activity: chosenPortType?.activity,
  })

  const { distanceInNm: distanceFromDeparture, isGetDistanceLoading } =
    useFetchDistance({
      source: prevMarker && (prevMarker as PortPredictorMarkerDeparture).posWkt,
      target: chosenPort?.pos_wkt,
    })

  const daysFromDeparture = calcDaysFromDeparture({
    departureMarker: prevMarker,
    distanceFromDeparture,
  })

  useEffect(() => {
    if (
      terminalTurnAroundTimeMinMedianMax?.median.value &&
      typeOfTurnAroundTimeData === TypeOfTurnAroundTimeData.UseTerminalData
    ) {
      setTurnAroundTime(terminalTurnAroundTimeMinMedianMax.median.value)
      return
    }
    if (
      portTurnAroundTimeMinMedianMax?.median.value &&
      typeOfTurnAroundTimeData === TypeOfTurnAroundTimeData.UsePortData
    ) {
      setTurnAroundTime(portTurnAroundTimeMinMedianMax.median.value)
      return
    }
  }, [
    portTurnAroundTimeMinMedianMax?.median.value,
    terminalTurnAroundTimeMinMedianMax?.median.value,
    // turnAroundTime,
    typeOfTurnAroundTimeData,
  ])

  useEffect(() => {
    /* 
    /* If you need to a state change consider if this can be done in the createMarkerBase 
    /* stage, or the useJourneyStart stage. Or in a seperate useEffect.
    */
    // if (currMarker.turnAroundTime === turnAroundTime) {
    //   return
    // }

    if (!currMarker.port) {
      // Only possible to updated marker with port turnaround time only if a
      // port has been selected.
      return
    }

    const updatedPortTurnAroundTime =
      turnAroundTime === undefined ? undefined : turnAroundTime

    onUpdateMarker({
      id: currMarker.id,
      portType: currMarker.portType,
      lastPosTime: currMarker.lastPosTime,
      cargo: currMarker.cargo,
      cargoGroup: currMarker.cargoGroup,
      port: currMarker.port,
      berth: chosenBerth,
      typeOfTurnAroundTimeData,
      laycanStart: currMarker.laycanStart,
      laycanEnd: currMarker.laycanEnd,
      markerType: MarkerType.Arrival,
      turnAroundTime: updatedPortTurnAroundTime,
      daysFromDeparture: currMarker.daysFromDeparture,
      distanceFromDeparture: currMarker.distanceFromDeparture,
      arrivalDate: currMarker.arrivalDate,
      vesselState: currMarker.vesselState,
    })
  }, [
    turnAroundTime,
    currMarker.lastPosTime,
    currMarker.daysFromDeparture,
    currMarker.distanceFromDeparture,
    currMarker.arrivalDate,
    currMarker.laycanStart,
    currMarker.laycanEnd,
    currMarker.id,
    currMarker.daysFromDeparture,
    currMarker.distanceFromDeparture,
    currMarker.portType,
    currMarker.cargo,
    currMarker.cargoGroup,
    currMarker.port,
    currMarker.turnAroundTime,
    currMarker.vesselState,
    prevMarker?.vesselState,
    chosenBerth,
    typeOfTurnAroundTimeData,
    // onUpdateMarker
  ])

  useEffect(() => {
    /*
    /* If you need to a state change consider if this can be done in the createMarkerBase 
    /* stage, or the useJourneyStart stage. Or in a seperate useEffect.
    */
    if (
      !prevMarker?.date ||
      !distanceFromDeparture ||
      !daysFromDeparture ||
      !chosenPort
    ) {
      return
    }

    if (
      chosenPort.locode === currMarker.port?.locode &&
      daysFromDeparture === currMarker.daysFromDeparture &&
      distanceFromDeparture === currMarker.distanceFromDeparture
    ) {
      return
    }

    onUpdateMarker({
      daysFromDeparture,
      distanceFromDeparture: distanceFromDeparture,
      lastPosTime: currMarker.lastPosTime,
      id: currMarker.id,
      markerType: currMarker.markerType,
      portType: currMarker.portType,
      cargo: currMarker.cargo,
      cargoGroup: currMarker.cargoGroup,
      port: chosenPort,
      turnAroundTime: currMarker.turnAroundTime,
      laycanStart: currMarker.laycanStart,
      laycanEnd: currMarker.laycanEnd,
      arrivalDate: moment(prevMarker.date)
        .add(daysFromDeparture, "days")
        .format("YYYY-MM-DD"),
      vesselState: currMarker.vesselState,
    })
  }, [
    distanceFromDeparture,
    daysFromDeparture,
    chosenPort,
    currMarker.arrivalDate,
    currMarker.lastPosTime,
    currMarker.id,
    currMarker.laycanStart,
    currMarker.laycanEnd,
    currMarker.markerType,
    currMarker.portType,
    currMarker.cargo,
    currMarker.port,
    currMarker.turnAroundTime,
    currMarker.vesselState,
    prevMarker?.date,
    currMarker.daysFromDeparture,
    currMarker.distanceFromDeparture,
    currMarker.cargoGroup,
    // onUpdateMarker,
  ])

  useEffect(() => {
    /**
     * Pass portTurnAroundTimeData to parent so we can display it on timeline right side
     */
    if (!isPortTurnAroundTimeSuccess || !portTurnAroundTimeData) {
      return
    }

    if (turnAroundTimeData && terminalTurnAroundTimeData) {
      // If the turnAroundTime has been prefilled by terminal data, do not replace it by port data
      return
    }

    setTurnAroundTimeData(portTurnAroundTimeData)
  }, [
    isPortTurnAroundTimeSuccess,
    portTurnAroundTimeData,
    setTurnAroundTimeData,
    terminalTurnAroundTimeData,
    turnAroundTimeData,
  ])

  useEffect(() => {
    /**
     * If user selects a terminal replace the portTurnAroundTimeData with the terminal data
     */
    if (!isTerminalTurnAroundTimeSuccess || !terminalTurnAroundTimeData) {
      return
    }

    setTurnAroundTimeData(terminalTurnAroundTimeData)
    setTypeOfTurnAroundTimeData(TypeOfTurnAroundTimeData.UseTerminalData)
    setTurnAroundTime(terminalTurnAroundTimeMinMedianMax?.median.value)

    onUpdateMarker({
      ...currMarker,
      typeOfTurnAroundTimeData: TypeOfTurnAroundTimeData.UseTerminalData,
      turnAroundTime: terminalTurnAroundTimeMinMedianMax?.median.value,
    })
  }, [
    isTerminalTurnAroundTimeSuccess,
    terminalTurnAroundTimeData,
    setTurnAroundTimeData,
    terminalTurnAroundTimeMinMedianMax?.median.value,
  ])

  useEffect(() => {
    const portType = findVesselPortType(currMarker.portType)
    setChosenPortType(portType)
  }, [currMarker.portType])

  const isGetDistanceReallyLoading =
    isGetDistanceLoading && !!chosenPort && !!chosenCargo
  const distanceFromDepartureDisplay =
    distanceFromDeparture &&
    BigNumber(distanceFromDeparture)
      .decimalPlaces(1, BigNumber.ROUND_CEIL)
      .toNumber()

  return (
    <div className="PortPredictor_MarkerArrivalInputs">
      <div className="mb-3 PortPredictor_InputsContainer">
        {/* If currMarker.id === 0 and it is an Arrival Marker (hence we use this component)
        but the vessel's initial state is Open Sea, that means the user has "changed to port"
        */}
        {currMarker.id === 0 && (
          <div className="PortPredictor_InputWrapper mb-2">
            <Form.Label className="PortPredictor_FormLabel fw-bold">
              <>Arrival Date</>
            </Form.Label>
            <div className="PortPredictor_InputGroup">
              <DatePicker
                disabled={!getTabStateKeyValue("isEditMode")}
                dateFormat="dd/MM/yyyy"
                placeholderText="Enter Arrival Date"
                selected={
                  currMarker.arrivalDate
                    ? new Date(currMarker.arrivalDate)
                    : null
                }
                onChange={(date) => {
                  if (!date) {
                    return
                  }
                  onUpdateMarker({
                    ...currMarker,
                    arrivalDate: moment(date).format("YYYY-MM-DD"),
                  })
                }}
              />
            </div>
          </div>
        )}
        <div className="PortPredictor_InputWrapper">
          <Form.Label className="PortPredictor_FormLabel fw-bold">
            <>Port Type</>
          </Form.Label>
          <Select
            isDisabled={!getTabStateKeyValue("isEditMode")}
            className="PortPredictor_InputGroup"
            options={portTypes}
            value={chosenPortType}
            onChange={(
              portType: SingleValue<PortPredictorPortTypeSelectOption>
            ) => {
              if (!portType) {
                return
              }
              const newTurnAroundTime =
                portType.value === PortType.Other
                  ? undefined
                  : currMarker.turnAroundTime
              const updatedMarker = {
                ...currMarker,
                portType: portType.value as PortType,
                laycanStart: "",
                laycanEnd: "",
                turnAroundTime: newTurnAroundTime,
                vesselState: getVesselStateForPortType({
                  chosenPortType: portType,
                  currMarkerVesselState: currMarker.vesselState,
                  previousMarkerVesselState: prevMarker?.vesselState,
                }),
              }

              onUpdateMarker(updatedMarker)
              setChosenPortType(portType)
              setTurnAroundTime(newTurnAroundTime)
            }}
          />
        </div>
        <div className="PortPredictor_InputWrapper mt-2">
          <PortPredictorPortsSelector
            isPortsError={isPortsError}
            isPortsLoading={isPortsLoading}
            portsData={portsData}
            chosenPortType={chosenPortType}
            chosenPort={chosenPort}
            chosenCargo={chosenCargo.cargo}
            isPortTurnAroundTimeLoading={isPortTurnAroundTimeLoading}
            chosenVesselStateData={chosenVesselStateData}
            setChosenPort={(port: PortPredictorPortsData | undefined) => {
              if (!port) {
                return
              }

              setChosenPort(port)
              setChosenBerth(undefined)
              onUpdateMarker({
                ...currMarker,
                port,
                berth: undefined,
              })

              // Zoom out or zoom in the map to fit only the user selected ports

              // if (map) {
              //   const updatedMarkers = []
              //   for (let marker of markers) {
              //     /**
              //      * If user has changed the port of a pre-existing marker, e.g. from , e.g. donghae -> hayport
              //      * to donghae -> singapore, we want to calculate the bounds from donghae -> singapore
              //      */
              //     if (currMarker.id === marker.markerId) {
              //       const newMarker = {
              //         markerId: currMarker.id,
              //         lat: port.lat,
              //         lon: port.lon,
              //       }
              //       updatedMarkers.push(newMarker)
              //     } else {
              //       updatedMarkers.push(marker)
              //     }
              //   }
              //   const bounds = L.latLngBounds(
              //     updatedMarkers.map((marker) => {
              //       return L.latLng({ lat: marker.lat, lng: marker.lon })
              //     })
              //   )

              //   bounds.extend({ lat: port.lat, lng: port.lon })
              //   map.fitBounds(bounds)
              // }
            }}
          />
        </div>
        {chosenPort &&
          chosenCargo &&
          !portTurnAroundTimeMinMedianMax &&
          !isPortTurnAroundTimeLoading &&
          chosenPort &&
          chosenCargo &&
          !isPortTurnAroundTimeLoading &&
          currMarker.portType !== PortType.Other && (
            <div className="PortPredictor_InputWrapper">
              No vessels found{" "}
              {chosenPortType?.activity === VesselActivity.Discharge
                ? "discharging"
                : "loading"}{" "}
              {chosenCargo.cargo} at {chosenPort?.port}
            </div>
          )}
        {chosenPortType && chosenPortType.value === PortType.Other && (
          <div className="PortPredictor_InputWrapper mt-2">
            <PortPredictorDaysInPortInput
              portTurnAroundTime={turnAroundTime ? String(turnAroundTime) : ""}
              setPortTurnAroundTime={(time) => {
                if (time === "") {
                  return
                }
                setTurnAroundTime(Number(time))
              }}
            />
          </div>
        )}
        {chosenPortType && chosenPortType.value !== PortType.Other && (
          <>
            <div className="PortPredictor_InputWrapper mt-2">
              <PortPredictorCargoInputs
                isDisabled={chosenPort === undefined}
                cargoesData={cargoesData}
                isCargoesLoading={isCargoesLoading}
                isCargoesError={isCargoesError}
                chosenCargo={chosenCargo}
                setChosenCargo={(chosenCargo) => {
                  if (
                    !chosenCargo ||
                    !chosenCargo.cargo ||
                    !chosenCargo.cargoGroup
                  ) {
                    return
                  }
                  onUpdateMarker({
                    ...currMarker,
                    cargo: chosenCargo.cargo,
                    cargoGroup: chosenCargo.cargoGroup,
                  })
                  setChosenCargo({
                    cargo: chosenCargo.cargo,
                    cargoGroup: chosenCargo.cargoGroup,
                  })
                }}
              />
            </div>
            {(chosenPortType.value === PortType.LoadWithLaycan ||
              chosenPortType.value === PortType.DischargeWithArrivalWindow) && (
              <div className="PortPredictor_InputWrapper mt-2 pt-1">
                <PortPredictorDatePicker
                  currMarkerLaycanStart={currMarker.laycanStart}
                  currMarkerLaycanEnd={currMarker.laycanEnd}
                  updateLaycan={({
                    date,
                    isStart,
                  }: {
                    date: Date
                    isStart: boolean
                  }) => {
                    const key = isStart ? "laycanStart" : "laycanEnd"
                    onUpdateMarker({
                      ...currMarker,
                      [key]: date.toISOString(),
                    })
                  }}
                  chosenPortType={chosenPortType}
                />
              </div>
            )}
            {!isStartOfJourney && (
              <div className="PortPredictor_InputWrapper mt-2">
                <PortPredictorDistanceToPortInput
                  distanceFromDepartureDisplay={distanceFromDepartureDisplay}
                  isGetDistanceReallyLoading={isGetDistanceReallyLoading}
                />
              </div>
            )}

            <div>
              <div className="PortPredictor_InputWrapper mt-2">
                <PortPredictorDaysInPortInput
                  portTurnAroundTime={
                    turnAroundTime === undefined ? "" : String(turnAroundTime)
                  }
                  setPortTurnAroundTime={(time) => {
                    if (time === "") {
                      return
                    }
                    setTurnAroundTime(Number(time))
                  }}
                />
              </div>
              <div className="PortPredictor_InputWrapper mt-2">
                <PortPredictorBerthsSelector
                  isDisabled={
                    !berthsData ||
                    berthsData.length < 1 ||
                    !getTabStateKeyValue("isEditMode")
                  }
                  isBerthsError={isBerthsError}
                  isBerthsLoading={isBerthsLoading}
                  berthsData={berthsData}
                  chosenBerth={chosenBerth}
                  chosenCargo={chosenCargo.cargo}
                  isTerminalTurnAroundTimeLoading={
                    isTerminalTurnAroundTimeLoading
                  }
                  isTerminalTurnAroundTimeError={isTerminalTurnAroundTimeError}
                  setChosenBerth={(
                    berth: PortPredictorBerthsData | undefined
                  ) => {
                    if (!berth) {
                      return
                    }
                    onUpdateMarker({
                      ...currMarker,
                      berth,
                    })
                    setChosenBerth(berth)
                  }}
                />
              </div>
              <PortOrTerminalTurnAroundTimeCheckBoxes
                portTurnAroundTimeData={portTurnAroundTimeData}
                terminalTurnAroundTimeData={terminalTurnAroundTimeData}
                typeOfTurnAroundTimeData={typeOfTurnAroundTimeData}
                setTypeOfTurnAroundTimeData={(
                  selectId: TypeOfTurnAroundTimeData
                ) => {
                  setTypeOfTurnAroundTimeData(selectId)
                }}
                setTurnAroundTimeData={(
                  data: PortPredictorTurnAroundTimeData[]
                ) => setTurnAroundTimeData(data)}
                setTurnAroundTime={(selectId: TypeOfTurnAroundTimeData) => {
                  let newTurnAroundTime
                  if (selectId === TypeOfTurnAroundTimeData.UsePortData) {
                    if (!portTurnAroundTimeMinMedianMax) {
                      return
                    }
                    newTurnAroundTime =
                      portTurnAroundTimeMinMedianMax.median.value
                    setTurnAroundTime(
                      portTurnAroundTimeMinMedianMax.median.value
                    )
                  }
                  if (selectId === TypeOfTurnAroundTimeData.UseTerminalData) {
                    if (!terminalTurnAroundTimeMinMedianMax) {
                      return
                    }
                    newTurnAroundTime =
                      terminalTurnAroundTimeMinMedianMax.median.value
                    setTurnAroundTime(
                      terminalTurnAroundTimeMinMedianMax.median.value
                    )
                  }

                  const updatedMarker = {
                    ...currMarker,
                    turnAroundTime: newTurnAroundTime,
                    typeOfTurnAroundTimeData: selectId,
                  }
                  onUpdateMarker(updatedMarker)
                }}
              />
              {portTurnAroundTimeMinMedianMax &&
                chosenPort &&
                chosenCargo.cargo && (
                  <>
                    <div className="PortPredictor_InputWrapper mt-2">
                      <PortPredictorMinMedianMaxButtons
                        segment={segment}
                        portType={chosenPortType}
                        cargo={chosenCargo.cargo}
                        portName={chosenPort.port}
                        portTurnAroundTimeMinMedianMax={getMinMedianMaxFromPortOrTerminal(
                          {
                            typeOfTurnAroundTimeData,
                            portTurnAroundTimeMinMedianMax,
                            terminalTurnAroundTimeMinMedianMax,
                          }
                        )}
                        portTurnAroundTime={turnAroundTime}
                        setPortTurnAroundTime={(time: number) =>
                          setTurnAroundTime(time)
                        }
                      />
                    </div>
                  </>
                )}
            </div>
          </>
        )}
        {!isStartOfJourney && (
          <div className="PortPredictor_DeleteMarkerButtonWrapper mt-1 mb-2">
            <Button
              disabled={!getTabStateKeyValue("isEditMode")}
              className="PortPredictor_DeleteMarkerButton"
              variant="outline-danger"
              size="sm"
              onClick={() => {
                onDeleteMarker({
                  ...currMarker,
                })
              }}
            >
              <i className="ti-trash me-1 PortPredictor_DeleteMarkerButton_Icon" />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

function getMinMedianMaxFromPortOrTerminal({
  typeOfTurnAroundTimeData,
  portTurnAroundTimeMinMedianMax,
  terminalTurnAroundTimeMinMedianMax,
}: {
  typeOfTurnAroundTimeData: TypeOfTurnAroundTimeData
  portTurnAroundTimeMinMedianMax: TurnAroundTimeMinMedianMax
  terminalTurnAroundTimeMinMedianMax:
    | TurnAroundTimeMinMedianMax
    | undefined
    | null
}) {
  if (
    terminalTurnAroundTimeMinMedianMax &&
    typeOfTurnAroundTimeData === TypeOfTurnAroundTimeData.UseTerminalData
  ) {
    return terminalTurnAroundTimeMinMedianMax
  }

  return portTurnAroundTimeMinMedianMax
}
